import React, {useState} from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useParams } from "react-router-dom";
// import { BFSelect, BFLabel } from '@integration-frontends/common/ui';
import { BFSelect } from '@integration-frontends/common/ui/forms/form-controls/select';
import { BFLabel, BFInput } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import { BFCheckbox, BFMultiSelect, BFButton } from '@integration-frontends/common/ui';

import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectClient,
  selectActiveSection,
  setActiveSection,
  createCredential,
  createWorkflow,
} from '@integration-frontends/workflow-manager/core/application';

import {CreateHighspotWorkflowFormContainer, HighspotCredentialForm, HighspotReview} from './workflow-form-highspot';
import { CreateWorkflowNav } from './create-workflow-nav';
import { CreateClientFormSection } from './create-client';


import './create-workflow-form.scss';

export const sections = [
  ['client-info', 'Client Info'],
  ['app-info', 'Integration Info'],
  ['workflow-info', 'Workflow Info'],
  ['review', 'Review'],
];

export const CreateWorkflowFormContainer = () => {
  const dispatch = useDispatch();

  const activeSection = useSelector(selectActiveSection);

  const nextSection = () => {
    const curr = sections.findIndex(el => el[0] === activeSection);
    if (curr === sections.length - 1) {return};
    dispatch(setActiveSection({activeSection: sections[curr + 1][0]}));
  }

  const previousSection = () => {
    const curr = sections.findIndex(el => el[0] === activeSection);
    if (curr === 0) {return};
    dispatch(setActiveSection({activeSection: sections[curr - 1][0]}));
  }

  return (
    <section id="create-workflow-form-container" className="create-workflow-form-container">
      <section className="forms-container">
      {(activeSection === 'client-info') && (
        <CreateClientFormSection onNext={nextSection} />
      )}

      {activeSection === 'app-info' && (
        <HighspotCredentialForm onBack={previousSection} onNext={() => dispatch(createCredential)}/>
      )}

      {activeSection === 'workflow-info' && (
        <>
        <CreateHighspotWorkflowFormContainer onBack={previousSection} onNext={nextSection}/>
        </>
      )}
      {activeSection === 'review' && (
        <>
        <CreateWorkflowNav
        onBack={previousSection}
        onNext={() => {
          //create workflow
        }}
        onNextActionName={'Publish Workflow'}
        title="Review"
        />
        <HighspotReview />
        </>
      )}
      </section>

    </section>
  );
}
