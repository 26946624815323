import { Brandfolder } from '@integration-frontends/workflow-manager/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const brandfolderAdapter = createEntityAdapter<Brandfolder>();

export const brandfolderSlice = createSlice({
  name: 'brandfolder',
  initialState: brandfolderAdapter.getInitialState(),
  reducers: {
    brandfoldersReceived: brandfolderAdapter.addMany,
  },
});
