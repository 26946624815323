import classNames from 'classnames';
import React, {
  HTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  TextareaHTMLAttributes,
  useState,
} from 'react';
import { IconSearch } from '../../icons';
import { withFormControlStyles } from './common';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import { Placement } from '@popperjs/core';
import './select.scss';
import './input.scss';

/**
 * Form label
 */
export const BFLabel = (
  props: HTMLAttributes<HTMLLabelElement> & LabelHTMLAttributes<HTMLLabelElement>,
) => (
  <label
    className={classNames(
      'text-primary',
      'overflow-ellipsis',
      'overflow-hidden',
      'mb-0',
      'inline-block',
      props.className,
    )}
    {...props}
  />
);

export const BFErrorLabel = (
  props: HTMLAttributes<HTMLLabelElement> & LabelHTMLAttributes<HTMLLabelElement>,
) => (
  <label
    className={classNames('overflow-ellipsis', 'overflow-hidden', 'text-danger', props.className)}
    style={{
      marginBottom: 3,
    }}
    {...props}
  />
);

/**
 * Form controls
 */
export enum InputSize {
  Small,
  Medium,
}

const inputSizeHeights = {
  [InputSize.Small]: 40,
  [InputSize.Medium]: 50,
};

export interface BFInputProps {
  size?: InputSize;
}

export const BFInput = withFormControlStyles<
  BFInputProps & HTMLAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>
>(
  React.forwardRef<HTMLInputElement, BFInputProps & HTMLAttributes<HTMLInputElement>>(
    ({ className, size, ...props }, ref) => (
      <input
        ref={ref}
        className={classNames(
          'text-base border border-bf-light-gray',
          'transition ease-in-out hover:border-bf-dark-gray',
          'focus:border-bf-darker-gray focus:outline-none focus:ring-bf-darker-gray',
          className,
        )}
        {...props}
        style={{ height: inputSizeHeights[size], ...props.style }}
      />
    ),
  ),
);

export const BFSearchInput = withFormControlStyles<
  BFInputProps & HTMLAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>
>(
  React.forwardRef<HTMLInputElement, BFInputProps & HTMLAttributes<HTMLInputElement>>(
    ({ className, size = InputSize.Medium, style, ...restProps }, ref) => (
      <span
        className={classNames(
          'inline-block relative border border-bf-light-gray',
          'transition ease-in-out hover:border-bf-dark-gray',
          className,
        )}
        style={{ height: inputSizeHeights[size], ...style }}
      >
        <div className="absolute h-full flex justify-center items-center pointer-events-none">
          <IconSearch className="text-primary" style={{ width: 14, height: 14, marginLeft: 12 }} />
        </div>
        <BFInput
          ref={ref}
          type="text"
          className="placeholder-bf-dark-gray w-full border-none"
          style={{ paddingLeft: 42, height: '100%' }}
          size={size}
          {...restProps}
        />
      </span>
    ),
  ),
);

export const BFTextArea = withFormControlStyles<
  HTMLAttributes<HTMLTextAreaElement> & TextareaHTMLAttributes<HTMLTextAreaElement>
>((props) => <textarea {...props} />);

export interface BFPopoverProps {
  button: JSX.Element;
  panel: JSX.Element;
  offset?: any;
  placement?: Placement;
  zIndex: number;
  closeOnClick?: boolean;
  panelClass?: string;
  id?: string;
}

export function BFPopover({
  button,
  panel,
  offset,
  placement,
  zIndex,
  closeOnClick = true,
  panelClass,
  id,
}: BFPopoverProps) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: styles, attributes: attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || 'bottom',
    modifiers: [{ name: 'offset', options: { offset: offset || [0, 0] } }],
  });
  const [selectorOpen, setSelectorOpen] = useState(false);

  const popoverPanel = (
    <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <div className={classNames('shadow-border left-0', panelClass)} ref={setPopperElement}>
        {panel}
      </div>
    </Popover.Panel>
  );
  return (
    <Popover id={id} data-cy="popover-menu" className="relative" style={{ zIndex }}>
      <Popover.Button as="div" ref={setReferenceElement}>
        {button}
        {closeOnClick && popoverPanel}
      </Popover.Button>
      {!closeOnClick && popoverPanel}
    </Popover>
  );
}
