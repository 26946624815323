import { createReducer } from '@reduxjs/toolkit';
import {
  credentialLoading,
  credentialSuccess,
  credentialFailure,
  setIntegrationType,
  highspotSetClientKey,
  highspotSetSecretKey,
} from './actions';

export interface CreateCredentialState {
  credentialLoading: boolean;
  integrationType: string;
  uploading: boolean;
  highspot: HighspotCredential | null;
}

export interface HighspotCredential{
  client_key: string;
  secret_key: string;
}


export const highspotCredentialInitialState: HighspotCredential = {
  client_key: '',
  secret_key: '',
}

export const createCredentialInitialState: CreateCredentialState = {
  credentialLoading: false,
  integrationType: '',
  uploading: false,
  highspot: highspotCredentialInitialState,
};

export const createCredentialReducer = createReducer(createCredentialInitialState, (builder) =>
  builder
  .addCase(setIntegrationType, (state, action) => {
    state.integrationType = action.payload.integrationType;
  })
  .addCase(credentialLoading, (state) => {
    state.credentialLoading = true;
  })
  .addCase(credentialSuccess, state => {
    state.credentialLoading = false;
  })
  .addCase(credentialFailure, (state) => {
    state.credentialLoading = false;
  })

  //Highspot

  .addCase(highspotSetClientKey, (state, action) => {
    state.highspot.client_key = action.payload.clientKey;
  })
  .addCase(highspotSetSecretKey, (state, action) => {
    state.highspot.secret_key = action.payload.secretKey;
  })
);

export interface CreateCredentialRootState {
  createCredentialReducer: CreateCredentialState;
}
