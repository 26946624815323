import React from 'react';
import { IconClose, BFButton, ButtonType, VerticalDividerLine } from '@integration-frontends/common/ui';
import { push } from 'redux-first-history';
import { useDispatch } from 'react-redux';

export interface CreateWorkflowNavProps {
  onBack?: () => void;
  onNext?: () => void;
  onNextActionName?: string;
  title: string;
}

export const CreateWorkflowNav = ({onBack, onNext, onNextActionName, title}: CreateWorkflowNavProps) => {
  const dispatch = useDispatch();
  return(
    <nav className="create-workflow-nav">
      <div className="create-workflow-nav-left">
        <IconClose onClick={() => dispatch(push('/workflows'))} />
        <VerticalDividerLine height={20}/>
        <span>{title}</span>
      </div>
      <div className="create-workflow-nav-right">
      <BFButton onClick={onBack} disabled={!onBack} buttonType={ButtonType.Secondary}>Back</BFButton>
      <BFButton onClick={onNext} disabled={!onNext}>{onNextActionName || 'Next'}</BFButton>
      </div>
    </nav>
  )
}
