import {
  GetBrandfolderFormInfoBody,
} from '@integration-frontends/common/temporal-api';

import {
  Brandfolder
} from './brandfolder';

export const FORM_INFO_REPO_TOKEN = 'FORM_INFO_REPO';

export interface IFormInfoRepo {
  getBrandfolderFormInfo: (attributes: GetBrandfolderFormInfoBody) => Promise<Brandfolder[]>;
};
