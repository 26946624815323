import {StateSelector} from "@integration-frontends/common/utils/redux";
import {clientAdapter} from "../entities";
import {Client} from "@integration-frontends/workflow-manager/core/model";
import { createSelector, EntityState } from '@reduxjs/toolkit';

export function createClientSelectors(stateSelector: StateSelector<EntityState<Client>>) {

  const baseSelectors = clientAdapter.getSelectors(stateSelector);

  const workflows = (clientId: string) =>
    createSelector(baseSelectors.selectAll, (clients) => {
      const foundClient: Client = clients.find(client => client.id === clientId);
      return foundClient.workflows;
    })

  return {
    ...baseSelectors,
    workflows,
  }
}
