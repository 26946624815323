import { CustomFieldKeyValueDto } from '@integration-frontends/common/brandfolder-api';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { Asset, Container, ResourceType } from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';

export const createClient = createAction(
  'CREATE_CLIENT',
  withPayloadType<{clientName: string, bfApiKey: string}>(),
)

export const selectClient = createAction(
  'SELECT_CLIENT',
  withPayloadType<{ clientId: string }>(),
);

export const loading = createAction('CLIENT_CREATE_LOADING');
export const success = createAction('CLIENT_CREATE_SUCCESS');
export const failure = createAction('CLIENT_CREATE_FAILURE');

export const setActiveSection = createAction(
  'SET_SECTION',
  withPayloadType<{ activeSection: string }>(),
)

export const createWorkflowInfoFormEntered = createAction('CREATE_WORKFLOW_INFOR_FORM_ENTERED');

export const createWorkflow = createAction('CREATE_WORKFLOW');


export const setSelectedCredentialId = createAction(
  'SELECT_CREDENTIAL',
  withPayloadType<{ credentialId: string }>(),
);

export const setBrandfolderFormInfo = createAction(
  'SET_BRANDFOLDER_FORM_INFO',
  withPayloadType<{ brandfolderFormInfo: any }>(),
);


// HIGH SPOT

export const highspotSelectWorkflowName = createAction(
  'HIGHSPOT_SELECT_WORKFLOW_NAME',
  withPayloadType<{
    workflowName: string;
  }>(),
);
export const highspotSelectBrandfolder = createAction(
  'HIGHSPOT_SELECT_BRANDFOLDER',
  withPayloadType<{
    brandfolder: string;
  }>(),
);

export const highspotSelectSection = createAction(
  'HIGHSPOT_SELECT_SECTION',
  withPayloadType<{
    section: string;
  }>(),
);

export const highspotSelectSpot = createAction(
  'HIGHSPOT_SELECT_SPOT',
  withPayloadType<{
    spot: string;
  }>(),
);

export const highspotSelectIntegrationID = createAction(
  'HIGHSPOT_SELECT_INTEGRATION_ID',
  withPayloadType<{
    integrationID: string;
  }>(),
);
export const highspotSelectAPIHost = createAction(
  'HIGHSPOT_SELECT_API_HOST',
  withPayloadType<{
    apiHost: string;
  }>(),
);
