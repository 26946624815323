import { all, call, put, takeEvery, select } from 'typed-redux-saga';
import {
  workflowEntityActions,
  selectSelectedClientId,
} from '@integration-frontends/workflow-manager/core/application';
import {
  WORKFLOW_REPO_TOKEN,
  IWorkflowRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import { workflowsPageEntered } from '../../workflows-page';

function* handler() {
  const selectedClientId = yield select(selectSelectedClientId);
  try {
    const workflowRepo: IWorkflowRepo = DI_CONTAINER.get(WORKFLOW_REPO_TOKEN);
    const workflows = yield call(workflowRepo.listWorkflows, selectedClientId);

    yield put(workflowEntityActions.workflowsReceived(workflows));
    yield put(pageLoaded());
  } catch (e) {
    yield put(pageLoadError());
  }
}

export function* enteredWorkflowsPageEffects() {
  yield takeEvery(workflowsPageEntered, handler);
}
