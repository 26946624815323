import {
  ALL_MEDIA_TYPES,
  IMediaTypeSupportService,
  MediaType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';

@injectable()
export class MediaTypeSupportServiceMock implements IMediaTypeSupportService {
  canBeOpened(mediaType: MediaType): Promise<boolean> {
    return Promise.resolve(true);
  }

  getExportableMediaTypes(): MediaType[] {
    return ALL_MEDIA_TYPES;
  }

  isSupported(mediaType: MediaType): boolean {
    return true;
  }
}
