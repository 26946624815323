import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames';
import { useParams } from "react-router-dom";
import { BFSelect } from '@integration-frontends/common/ui/forms/form-controls/select';
import { BFLabel, BFInput } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import { BFCheckbox, BFMultiSelect, BFButton } from '@integration-frontends/common/ui';
import { CreateWorkflowNav } from '../create-workflow-nav';
import { SectionTracker } from '../section-tracker';

import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectClient,
  selectHighspotWorkflowName,
  highspotSelectWorkflowName,
  selectHighspotIntegrationID,
  highspotSelectIntegrationID,
  selectHighspotAPIHost,
  highspotSelectAPIHost,
  selectActiveSection,
  createWorkflowInfoFormEntered,
  selectBrandfolderFormInfo,
  selectHighspotBrandfolder,
  highspotSelectBrandfolder,
  selectHighspotSection,
  highspotSelectSection,
  credentialEntitySelectors,
} from '@integration-frontends/workflow-manager/core/application';


import '../create-workflow-form.scss';

interface CreateHighspotWorkflowFormContainerProps {
  onBack: () => void;
  onNext: () => void;
}

export const CreateHighspotWorkflowFormContainer = ({onBack, onNext}: CreateHighspotWorkflowFormContainerProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createWorkflowInfoFormEntered());
  }, [])
  const activeSection = useSelector(selectActiveSection);
  const clientId = useSelector(selectSelectedClientId);
  const integrationID = useSelector(selectHighspotIntegrationID);
  const apiHost = useSelector(selectHighspotAPIHost);
  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);

  const workflowName = useSelector(selectHighspotWorkflowName);
  const bfSources = brandfolderFormInfo?.map((bf) => {
    return {label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections};
  })
  const bfSource = useSelector(selectHighspotBrandfolder);

  const selectBfSource = (value) => {
    dispatch(highspotSelectBrandfolder({brandfolder: value}));
    setSelectedSection(null);
  }
  const [selectedSection, setSelectedSection] = useState(null);
  const bfSections = bfSources?.find(bf => bf.value === bfSource)?.sections?.data.map(section => {
    return {label: section.name, value: section.id};
  })

  const selectBfSection = (value) => {
    dispatch(highspotSelectSection({section: value}));
  }

  const highspotSection = useSelector(selectHighspotSection);

  const [sectionMapsAmount, setSectionMapsAmount] = useState(1);
  const [collectionMapsAmount, setCollectionMapsAmount] = useState(1);

  const [clientName, setClientName] = useState(null);
  const clients = useSelector(clientEntitySelectors.selectAll);
  const credentials = useSelector(credentialEntitySelectors.credentialsByType('highspot'));
  const [collectionToFolderMap, setCollectionToFolderMap] = useState({});

  const [secretId, setSecretId] = useState(null);

  const [sectionToFolderMap, setSectionToFolderMap] = useState({});

  const [spotId, setSpotId] = useState(null);
  const [email, setEmail] = useState(null);

  const [selectedSectionsToFolder, setSelectedSectionsToFolder] = useState([]);

  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedCollectionsToFolder, setSelectedCollectionsToFolder] = useState([]);

  const bfCollections = [{label: 'Collection 1', value: '1'}, {label: 'Collection 2', value: '2'}];
  const folders = [{label: 'Folder 1', value: '1'}, {label: 'Folder 2', value: '2'}];

  const spots = [{label: 'Spot 1', value: '1'}, {label: 'Spot 2', value: '2'}]

  return (
    <>
      <CreateWorkflowNav
      onBack={onBack}
      onNext={onNext}
      title="Workflow Info"
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection}/>
        <section className="forms-container">
          <h1>Workflow Info</h1>

          <BFLabel htmlFor="select-api-host">Workflow Name</BFLabel>
          <BFInput
          id="select-workflow-name"
          value={workflowName}
          onChange={(e) => {
            dispatch(highspotSelectWorkflowName({ workflowName: e.target.value }));
          }}/>

          <section className="source-section workflow-info-section">
          <header className="workflow-info-section-header">
            Source Brandfolder
          </header>
            <section className="workflow-info-section-body">
              <BFLabel htmlFor="select-brandfolder">Brandfolder</BFLabel>
              <BFSelect
              id="select-brandfolder"
              required={true}
              options={bfSources || []}
              value={bfSources?.find(bf => bf.value === bfSource)?.value}
              onOptionChange={(e) => selectBfSource(e.value)}
              placeholder={"Select Brandfolder"}
              />

              <BFLabel htmlFor="select-section">Brandfolder section (optional)</BFLabel>
              <BFSelect
              id="select-section"
              required={false}
              disabled={!bfSource}
              options={bfSections || []}
              placeholder={"Select Section"}
              value={highspotSection}
              onOptionChange={(e) => selectBfSection(e.value)}
              />
            </section>
          </section>

          <section className="destination-section workflow-info-section">
            <header className="workflow-info-section-header">
              Destination Highspot
            </header>
            <section className="workflow-info-section-body">
              <BFLabel htmlFor="select-credentials-id">Credential Id</BFLabel>
              <BFSelect id="select-credentials-id" options={credentials.map(
                credential => ({label: credential.id, value: credential.id})
              )}/>

              <BFLabel htmlFor="select-spot-id">The Spot</BFLabel>
              <BFSelect
              id="select-spot-id"
              options={spots}
              placeholder={"Choose Spot"}
              value={spots.filter((spot) => spotId === spot.value)[0]?.value || null}
              onOptionChange={(e) => {
                setSpotId(e.value);
              }}
              />

              <BFLabel htmlFor="select-integration-id">Integration ID</BFLabel>
              <BFInput
              id="select-api-host"
              value={integrationID}
              onChange={(e) => {
                dispatch(highspotSelectIntegrationID({ integrationID: e.target.value }));
              }}/>

              <BFLabel htmlFor="select-api-host">Api Host</BFLabel>
              <BFInput
              id="select-api-host"
              value={apiHost}
              onChange={(e) => {
                dispatch(highspotSelectAPIHost({ apiHost: e.target.value }));
              }}/>
            </ section>
          </section>
        </section>
      </section>
    </>
  );
}

const getGroupToFolderMap = (group, groupToFolder) => {
  const groupToFolderMap = {};

  group.forEach((collection, i) => {
    groupToFolderMap[collection.value] = groupToFolder[i].value
  })
  return groupToFolderMap;
};
