import { withPayloadType } from '@integration-frontends/common/utils/redux';
import {
  Attachment,
  AttachmentVariant,
  ResourceBaseSortableProperty,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';
import { ISelectAttachmentOptions } from '../select-attachment-options';

// init
export const selectAttachmentInit = createAction('SELECT_ATTACHMENT_INIT');
export const selectAttachmentInitSuccess = createAction(
  'SELECT_ATTACHMENT_INIT_SUCCESS',
  withPayloadType<{ selectAttachmentOptions: ISelectAttachmentOptions }>(),
);

// select assets/attachments
export const assetDetailsPageEntered = createAction(
  'ENTERED_ASSET_DETAILS',
  withPayloadType<{ assetId: string }>(),
);
export const assetDetailsDataLoaded = createAction('ASSET_DETAILS_DATA_LOADED');
export const attachmentSelected = createAction(
  'ATTACHMENT_SELECTED',
  withPayloadType<{ attachment: Attachment | AttachmentVariant }>(),
);
export const endAttachmentSelection = createAction(
  'ATTACHMENT_SELECTION_ENDED',
  withPayloadType<{ attachments: (Attachment | AttachmentVariant)[] }>(),
);
export const enteredAttachmentDetails = createAction(
  'ENTERED_ATTACHMENT_DETAILS',
  withPayloadType<{ attachmentId: string }>(),
);
export const goToAssetDetails = createAction(
  'GO_TO_ASSET_DETAILS',
  withPayloadType<{ assetId: string }>(),
);
export const multiSelectAttachmentEnded = createAction(
  'MULTI_SELECT_EVENT_ENDED',
  withPayloadType<{ attachments: (Attachment | AttachmentVariant)[] }>(),
);
export const singleSelectAttachmentEnded = createAction(
  'SINGLE_SELECT_ENDED',
  withPayloadType<{ attachments: (Attachment | AttachmentVariant)[] }>(),
);
export const attachmentDeselected = createAction(
  'ATTACHMENT_DESELECTED',
  withPayloadType<{ attachment: Attachment }>(),
);
export const selectAllAttachments = createAction(
  'ALL_ATTACHMENTS_SELECTED',
  withPayloadType<{ attachments: (Attachment | AttachmentVariant)[] }>(),
);
export const showAttachmentsOnly = createAction('SHOW_ATTACHMENTS_ONLY');
export const deselectAllAttachments = createAction('ALL_ATTACHMENTS_DESELCTED');

export const attachmentDataLoaded = createAction('ATTACHMENT_DATA_LOADED');

export const initiateAttachmentActionClicked = createAction(
  'INITIATE_ATTACHMENT_ACTION_CLICKED',
  withPayloadType<{
    callback: (attachments: (Attachment | AttachmentVariant)[]) => Promise<void>;
    attachments: (Attachment | AttachmentVariant)[];
    done?: () => void;
  }>(),
);

// show page
export const showPageEntered = createAction(
  'SHOW_PAGE_ENTERED',
  withPayloadType<{
    containerId: string;
    searchParams?: SearchParameters;
    sortOptions?: SortOptions<ResourceBaseSortableProperty>;
  }>(),
);
export const showPageDataLoaded = createAction('SHOW_PAGE_DATA_LOADED');
export const showPageSearch = createAction(
  'SHOW_PAGE_SEARCH',
  withPayloadType<{
    searchParams: SearchParameters;
    sortOptions?: SortOptions<ResourceBaseSortableProperty>;
  }>(),
);

export const showPageScrollStopped = createAction(
  'SHOW_PAGE_SCROLL_STOPPED',
  withPayloadType<{ scrollPosition: number }>(),
);

// org landing page
export const orgLandingPageEntered = createAction('ORG_LANDING_PAGE_ENTERED');
export const orgLandingPageDataLoaded = createAction('ORG_LANDING_PAGE_DATA_LOADED');

// navigation
export const goToContainerSelectorStep = createAction('GO_TO_CONTAINER_SELECTOR_STEP');
export const goToAssetSelectorStep = createAction('GO_TO_ASSET_SELECTOR_STEP');
