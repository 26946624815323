import {
  CreateClientBody,
} from '@integration-frontends/common/temporal-api';

export interface Client {
  id: string;
  brandfolder_account_id: Number;
  client_name: string;
  workflows: WorkflowMap[];
}

export interface Credential {
  [key: string]: string;
}

export interface WorkflowMap {
  [key: string]: string;
}

export const CLIENT_REPO_TOKEN = 'CLIENT_REPO';
export interface IClientRepo {
  listClients: () => Promise<Client[]>;
  createClient: (clientBody: CreateClientBody) => Promise<Client>;
}
