import {CreateClientForm} from '../clients-page/create-client';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectClient,
  selectActiveSection,
} from '@integration-frontends/workflow-manager/core/application';
import { CreateWorkflowNav } from './create-workflow-nav';
import { SectionTracker } from './section-tracker';

interface CreateClientFormSectionProps {
  onNext: () => void;
}

export const CreateClientFormSection = ({onNext}: CreateClientFormSectionProps) => {
  const activeSection = useSelector(selectActiveSection);
  return(
    <>
      <CreateWorkflowNav
      onNext={onNext}
      title="Client Info"
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection}/>
        <section className="forms-container">
          <h1>Client Info</h1>
          <CreateClientForm modal={false} onClose={() => {}} />
        </section>
      </section>
    </>
  )
}
