/**
 * Brandfolder API model
 */
import { curry, identity, pickBy } from 'ramda';

export const SUPPORTED_IMAGE_EXTENSIONS = ['ai', 'jpg', 'jpeg', 'png', 'svg'];

export interface CreateClientBody {
  bf_api_key: string;
  client_name: string;
}

export interface GetBrandfolderFormInfoBody {
  credential_id: string;
}

export interface GetHighspotFormInfoBody {
  credential_id: string;
}

export interface CreateHighspotCredentialBody {
  client_key: string;
  client_secret: string;
  credential_type: CredentialType;
}

export enum CredentialType {
  HIGHSPOT = 'highspot',
  HUBSPOT = 'hubspot',
  GETTY = 'getty',
  BRANDFOLDER = 'brandfolder',
}

export enum ResourceType {
  ASSET = 'assets',
  ATTACHMENT = 'attachments',
  BRANDFOLDER = 'brandfolders',
  COLLECTION = 'collections',
  CUSTOM_FIELD_KEY = 'custom_field_keys',
  CUSTOM_FIELD_VALUE = 'custom_field_values',
  CUSTOM_FIELD_VALUE_OPTION = 'custom_field_value_option',
  GENERIC_FILE = 'generic_files',
  LABEL = 'labels',
  ORGANIZATION = 'organizations',
  SEARCH_FILTER = 'search_filters',
  SECTION = 'sections',
  SHARE_MANIFEST = 'share_manifests',
  TAG = 'tags',
  USER = 'users',
}

export enum Relationship {
  ASSET = 'asset',
  ASSETS = 'assets',
  ATTACHMENTS = 'attachments',
  BRANDFOLDER = 'brandfolder',
  BRANDFOLDERS = 'brandfolders',
  COLLECTIONS = 'collections',
  CUSTOM_FIELDS = 'custom_fields',
  ORGANIZATION = 'organization',
  SECTION = 'section',
  SECTIONS = 'sections',
  TAGS = 'tags',
}

export interface RelationshipResourceDto<T extends ResourceType = ResourceType> {
  id: string;
  type: T;
}

export interface ManyToOneRelationshipDto<
  T extends RelationshipResourceDto = RelationshipResourceDto,
> {
  data: T;
}

export interface OneToManyRelationshipDto<
  T extends RelationshipResourceDto = RelationshipResourceDto,
> {
  data: T[];
}

export interface ManyToManyRelationshipDto<
  T extends RelationshipResourceDto = RelationshipResourceDto,
> {
  data: T[];
}

export interface ResourceDtoBase {
  id?: string;
  type: ResourceType;
}

export interface ResourceAttributesDto {
  name: string;
  slug: string;
  position: number;
  created_at?: string;
  updated_at?: string;
  default_asset_type?: string;
}

export interface AssetDto extends ResourceDtoBase {
  type: ResourceType.ASSET;
  attributes: ResourceAttributesDto & {
    attachment_count?: number;
    thumbnail_url?: string;
    description?: string;
    availability_end: Date;
    availability: string;
  };
  relationships: {
    [Relationship.BRANDFOLDER]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.BRANDFOLDER>
    >;
    [Relationship.SECTION]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.SECTION>
    >;
    [Relationship.ATTACHMENTS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.ATTACHMENT>
    >;
    [Relationship.CUSTOM_FIELDS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.CUSTOM_FIELD_VALUE>
    >;
  };
}

export interface AttachmentDto extends ResourceDtoBase {
  type: ResourceType.ATTACHMENT;
  attributes: ResourceAttributesDto & {
    thumbnail_url?: string;
    cdn_url?: string;
    url: string;
    filename: string;
    extension: string;
    mimetype: string;
    width: number;
    height: number;
    size: number;
  };
  relationships: {
    [Relationship.ASSET]: ManyToOneRelationshipDto<RelationshipResourceDto<ResourceType.ASSET>>;
  };
}

export interface AttachmentInputDto {
  type: ResourceType.ATTACHMENT;
  url: string;
  filename: string;
  mimetype: string;
}

export type IncludedAttachmentDto = Omit<AttachmentDto, 'relationships'>;

export function getAttachmentDtoUrl(attachmentDto: AttachmentDto): string {
  return attachmentDto.attributes.cdn_url || attachmentDto.attributes.url;
}

export interface BrandfolderDto extends ResourceDtoBase {
  type: ResourceType.BRANDFOLDER;
  attributes: ResourceAttributesDto & {
    asset_count?: number;
    attachment_count?: number;
    card_image?: string;
    storage?: string;
  };
  relationships: {
    [Relationship.ASSETS]?: OneToManyRelationshipDto<RelationshipResourceDto<ResourceType.ASSET>>;
    [Relationship.COLLECTIONS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.COLLECTION>
    >;
    [Relationship.ORGANIZATION]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.ORGANIZATION>
    >;
    [Relationship.SECTIONS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.SECTION>
    >;
  };
}

export type IncludedBrandfolderDto = Omit<BrandfolderDto, 'relationships'>;

export interface CollectionDto extends ResourceDtoBase {
  type: ResourceType.COLLECTION;
  attributes: ResourceAttributesDto & {
    asset_count?: number;
    attachment_count?: number;
  };
  relationships: {
    [Relationship.ASSET]?: ManyToManyRelationshipDto<RelationshipResourceDto<ResourceType.ASSET>>;
    [Relationship.BRANDFOLDER]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.BRANDFOLDER>
    >;
    [Relationship.ORGANIZATION]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.ORGANIZATION>
    >;
  };
}

export interface OrganizationDto extends ResourceDtoBase {
  type: ResourceType.ORGANIZATION;
  attributes: ResourceAttributesDto & {
    asset_count?: number;
  };
  relationships: {
    [Relationship.ASSETS]?: OneToManyRelationshipDto<RelationshipResourceDto<ResourceType.ASSET>>;
    [Relationship.BRANDFOLDERS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.BRANDFOLDER>
    >;
    [Relationship.COLLECTIONS]?: OneToManyRelationshipDto<
      RelationshipResourceDto<ResourceType.COLLECTION>
    >;
  };
}

export interface SearchFilterDto extends ResourceDtoBase {
  type: ResourceType.SEARCH_FILTER;
  attributes: ResourceAttributesDto & {
    label: string;
    query: string;
    featured: boolean;
  };
}

export interface SectionDto extends ResourceDtoBase {
  type: ResourceType.SECTION;
  attributes: ResourceAttributesDto;
  relationships: {
    [Relationship.BRANDFOLDER]?: ManyToOneRelationshipDto<
      RelationshipResourceDto<ResourceType.BRANDFOLDER>
    >;
  };
}

export interface UserDto extends ResourceDtoBase {
  type: ResourceType.USER;
  attributes: {
    email: string;
    first_name: string;
    last_name: string;
  };
}

export interface TagDto extends ResourceDtoBase {
  type: ResourceType.TAG;
  attributes: ResourceAttributesDto & {
    auto_generated: boolean;
  };
}

export interface CustomFieldKeyDto extends ResourceDtoBase {
  type: ResourceType.CUSTOM_FIELD_KEY;
  attributes: {
    allowed_values: string[];
    restricted: boolean;
    prioritized: boolean;
  } & ResourceAttributesDto;
}

export interface CustomFieldKeyResponseDatum {
  id: string;
  type: string;
  attributes: {
    allowed_values: string[];
    name: string;
    prioritized: boolean;
    required: boolean;
    restricted: boolean;
  };
}

export interface CustomFieldKeyResponseData {
  data: CustomFieldKeyResponseDatum[];
  meta: {
    current_page: number | null;
    next_page: number | null;
    prev_page: number | null;
    total_pageS: number;
    total_count: number;
  };
}

export interface CustomFieldValueDto extends ResourceDtoBase {
  attributes: ResourceAttributesDto & {
    key: string;
    value: string;
  };
}

export interface CustomFieldKeyValueDto {
  [key: string]: string[];
}

export interface CustomFieldValueOptions {
  data: CustomFieldValueResponseDto;
}

export interface CustomFieldValueResponseDto {
  custom_field_values: string[];
}

export interface ShareManifestDto extends ResourceDtoBase {
  attributes: ResourceAttributesDto & {
    availability_end: string;
    availability_start: string;
    name: string;
    link: string;
    view_only: boolean;
  };
}

export interface GenericFileDto extends ResourceDtoBase {
  type: ResourceType.GENERIC_FILE;
  attributes: ResourceAttributesDto;
}

export interface LabelDto extends ResourceDtoBase {
  type: ResourceType.LABEL;
  attributes: ResourceAttributesDto & {
    path: string[];
    depth: number;
  };
}

export type ResourceDto =
  | AssetDto
  | AttachmentDto
  | CustomFieldKeyDto
  | CustomFieldValueDto
  | IncludedAttachmentDto
  | BrandfolderDto
  | IncludedBrandfolderDto
  | CollectionDto
  | OrganizationDto
  | SearchFilterDto
  | SectionDto
  | TagDto
  | ShareManifestDto
  | GenericFileDto
  | LabelDto;

export function isSectionDto(resource: ResourceDto): resource is SectionDto {
  return resource.type === ResourceType.SECTION;
}

interface ApiDataResponseBase {
  included: ResourceDto[];
}

interface ApiDataResponseError {
  errors: { title: string; detail: string }[];
}

export type ApiFetchDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> = {
  data: T;
} & ApiDataResponseBase;

export type ApiFetchDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiFetchDataResponseSuccess<T>
  | ApiDataResponseError;

export type ApiListDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> = {
  data: T[];
  meta?: {
    total_count?: number;
    current_page?: number;
    next_page?: number;
    prev_page?: number;
    total_pages?: number;
  };
} & ApiDataResponseBase;

export type ApiListDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiListDataResponseSuccess<T>
  | ApiDataResponseError;

export type ApiSearchableThingsResponse = {
  custom_fields: { [name: string]: string }[];
  filetypes: { extension: string; count: number }[];
  tags: { name: string; count: number }[];
};

export type ApiDataResponseSuccess<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiFetchDataResponseSuccess<T>
  | ApiListDataResponseSuccess<T>;

export type ApiDataResponse<T extends ResourceDtoBase = ResourceDtoBase> =
  | ApiDataResponseSuccess<T>
  | ApiDataResponseError;

export function isError(response: ApiDataResponse): response is ApiDataResponseError | null {
  if (!response) return true;
  return (<ApiDataResponseError>response).errors?.length > 0;
}

export const getResponseData = <T extends ApiDataResponseSuccess>(response: T): T['data'] =>
  response.data;

export const getResponseDataOrDefault = <T extends ApiDataResponseSuccess>(
  response: T | ApiDataResponseError,
): T['data'] => (isError(response) ? null : response.data);

export const getResponseListData = <T extends ApiListDataResponseSuccess>(response: T): T['data'] =>
  response.data;

export const getResponseListDataOrDefault = <T extends ApiListDataResponseSuccess>(
  response: T | ApiDataResponseError,
): T['data'] => (isError(response) ? [] : getResponseListData(response));

export const getResponseIncluded = <T extends ApiDataResponseSuccess>(
  response: T | ApiDataResponseError,
): T['included'] => (isError(response) ? [] : response.included);

export function getResponseMeta<T extends ApiListDataResponseSuccess>(
  response: T | ApiDataResponseError,
): T['meta'] {
  return isError(response) ? {} : response.meta;
}

export const getRelationshipsData = curry(
  (apiResponse: ApiDataResponseSuccess, relationshipDto: RelationshipResourceDto): ResourceDto => {
    return getResponseIncluded(apiResponse).find((resource) => resource.id === relationshipDto.id);
  },
);

export enum FieldParameter {
  AssetCount = 'asset_count',
  AttachmentCount = 'attachment_count',
  Availability = 'availability',
  CardImage = 'card_image',
  CdnUrl = 'cdn_url',
  CreatedAt = 'created_at',
  ExpiresOn = 'availability_end',
  MuxHlsUrl = 'mux_hls_url',
  Storage = 'storage',
  ThumbnailUrl = 'thumbnail_url',
  UpdatedAt = 'updated_at',
}

export enum SortField {
  Name = 'name',
  Position = 'position',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  Score = 'score',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface Options<
  FP extends FieldParameter = FieldParameter,
  I extends Relationship = Relationship,
> {
  data?: any;
  fields?: FP[];
  include?: I[];
  search?: string; // TODO: improve type safety. ~PP
  per?: number;
  page?: number;
  sort?: {
    field: SortField;
    direction?: SortDirection;
  };
}

export type AssetFieldParameter =
  | FieldParameter.AttachmentCount
  | FieldParameter.CardImage
  | FieldParameter.CdnUrl
  | FieldParameter.CreatedAt
  | FieldParameter.ExpiresOn
  | FieldParameter.UpdatedAt
  | FieldParameter.Availability;
export type AssetIncludeParameter =
  | Relationship.BRANDFOLDER
  | Relationship.SECTION
  | Relationship.ATTACHMENTS
  | Relationship.CUSTOM_FIELDS
  | Relationship.TAGS;
export type AssetOptions = Options<AssetFieldParameter, AssetIncludeParameter>;

export type AttachmentFieldParameter =
  | FieldParameter.CdnUrl
  | FieldParameter.CreatedAt
  | FieldParameter.ThumbnailUrl
  | FieldParameter.MuxHlsUrl
  | FieldParameter.UpdatedAt;
export type AttachmentIncludeParameter = Relationship.ASSET | Relationship.TAGS;
export type AttachmentOptions = Options<AttachmentFieldParameter, AttachmentIncludeParameter>;

export type BrandfolderFieldParameter =
  | FieldParameter.AssetCount
  | FieldParameter.AttachmentCount
  | FieldParameter.CardImage
  | FieldParameter.Storage;
export type BrandfolderIncludeParameter =
  | Relationship.ASSETS
  | Relationship.ORGANIZATION
  | Relationship.SECTIONS;
export type BrandfolderOptions = Options<BrandfolderFieldParameter, BrandfolderIncludeParameter>;

export type CollectionFieldParameter = FieldParameter.AssetCount | FieldParameter.AttachmentCount;
export type CollectionIncludeParameter = Relationship.ASSETS | Relationship.BRANDFOLDER;
export type CollectionOptions = Options<CollectionFieldParameter, CollectionIncludeParameter>;

export type SectionFieldParameter = any;
export type SectionIncludeParameter = Relationship.BRANDFOLDER;
export type SectionOptions = Options<SectionFieldParameter, SectionIncludeParameter>;

export type OrganizationFieldParameter = any;
export type OrganizationIncludeParameter = Relationship.COLLECTIONS;
export type OrganizationOptions = Options<OrganizationFieldParameter, OrganizationIncludeParameter>;

export function optionsToQueryString(options: Options): string {
  const params = {
    fields: options?.fields?.join(','),
    include: options?.include?.join(','),
    search: options?.search,
    per: options?.per || 9999,
    page: options?.page,
    sort_by: options?.sort?.field,
    order: options?.sort?.direction,
    queue_priority: 'high',
  };

  return `${options ? '?' + new URLSearchParams(pickBy(identity, params)).toString() : ''}`;
}
