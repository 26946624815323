import { all, call, put, takeEvery } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  clientEntityActions,
} from '@integration-frontends/workflow-manager/core/application';
import {
  CLIENT_REPO_TOKEN,
  IClientRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import { createClient, loading, success, failure, selectClient } from '../../../create-workflow';

function* handler(action: ReturnType<typeof createClient>) {
  yield put(loading())
  const {bfApiKey, clientName} = action.payload;
  const clientRepo: IClientRepo = DI_CONTAINER.get(CLIENT_REPO_TOKEN);

  const clientInfo = {bf_api_key: bfApiKey, client_name: clientName};
  const client = yield call(clientRepo.createClient, clientInfo);

  if (client) {
    yield put(clientEntityActions.clientReceived(client));
    yield put(success())
    yield put(selectClient(client.id));
  } else {
    yield put(failure())
    yield put(createNotification({message: "Error creating client", location: 'topLevel', type: NotificationType.Error}));
  }
}

export function* createClientEffects() {
  yield takeEvery(createClient, handler);
}
