export interface SalsifyWorkflow {
  active: boolean;
  bfSourceKey: string;
  brandfolderAccountID: Number;
  clientName: string;
  createdAt: string;
  credentialID: string;
  customFieldMap: string;
  destConfig: string;
  id: string;
  identifierMap: string;
  orgID: string;
  updatedAt: string
};

export interface HighspotWorkflow {
  active: {
    bool: true;
    valid: true;
  };
  apiHost: string;
  bfSourceKey: string;
  brandfolderAccountID: 0;
  clientName: string;
  collectionToFolderMap: {
    string: string;
    valid: true;
  };
  createdAt: string;
  credentialID: string;
  id: string;
  integrationID: string;
  sectionToFolderMap: {
    string: string;
    valid: true;
  };
  spotID: {
    string: string;
    valid: true;
  };
  updatedAt: string;
};


export interface WorkflowListItem {
  id: string,
  brandfolder: string,
  brandfolder_account_id: number,
  workflow_name: string,
  integration_type: string,
  active: boolean,
  total_assets_affected: number,
  created_at: string,
  updated_at: string,
  last_updated_by: string
}

export const WORKFLOW_REPO_TOKEN = 'WORKFLOW_REPO';

export interface IWorkflowRepo {
  // getWorkflow: () => Promise<SalsifyWorkflow | HighspotWorkflow>;
  listWorkflows: (clientId: string) => Promise<WorkflowListItem[]>;
  // createWorkflow: (clientBody: CreateWorkflowBody) => Promise<Workflow>;
};
