import { CustomFieldKeyValueDto } from '@integration-frontends/common/brandfolder-api';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { Asset, Container, ResourceType } from '@integration-frontends/integration/core/model';
import { createAction } from '@reduxjs/toolkit';

export const createCredential = createAction('CREATE_CREDENTIAL');

export const enteredCredentialPage = createAction('ENTERED_CREDENTIAL_PAGE');

export const setIntegrationType = createAction(
  'SELECT_INTEGRATION_TYPE',
  withPayloadType<{ integrationType: string }>(),
);

export const credentialLoading = createAction('CREDENTIAL_CREATE_LOADING');
export const credentialSuccess = createAction('CREDENTIAL_CREATE_SUCCESS');
export const credentialFailure = createAction('CREDENTIAL_CREATE_FAILURE');



// HIGH SPOT

export const highspotSetClientKey = createAction(
  'HIGHSPOT_SELECT_API_KEY',
  withPayloadType<{
    clientKey: string;
  }>(),
);
export const highspotSetSecretKey = createAction(
  'HIGHSPOT_SELECT_SECRET_KEY',
  withPayloadType<{
    secretKey: string;
  }>(),
);
