
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    oauthPageEntered,
} from '@integration-frontends/workflow-manager/core/application';
import { BFButton, PrimaryLogo } from '@integration-frontends/common/ui';
import {loginThunk} from '@integration-frontends/common/auth/core/application';
import { useAuth } from "react-oidc-context";
import { Loader } from '@integration-frontends/common/ui';

import './oauth-page.scss';


export const OauthPage = () => {
    const auth = useAuth();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(oauthPageEntered());
    }, [])

    useEffect(() => {
        if (auth.isAuthenticated) {
            dispatch(loginThunk(auth.user.access_token) as any);
        }
    }, [auth.isAuthenticated])

    const handleSignin = () => {
        void auth.signinRedirect()
    }

    return (
        <section className="oauth-page">
            <PrimaryLogo className="logo" />
            <div className="oauth-content">
                <header className="flex flex-row page-header">
                    <h1 className="page-header-title">{"Integrations Workflow Manager"}</h1>
                </header>
                {(auth.isLoading)
                    ? <Loader />
                    : <>
                        <div className="oauth-card">
                            <header className="flex flex-row">
                                <h1 className="page-header-action">{"Sign in to your account"}</h1>
                            </header>
                            {(auth.error) && <div>Oops... {auth.error.message}</div>}
                            {(auth.isAuthenticated)
                                ? <div>Loggin you in...</div>
                                : <BFButton className="sign-in-button h-full flex flex-row" onClick={() => handleSignin()}>
                                    <div className="flex flex-row">
                                        <div>Sign In</div>
                                    </div>
                                </BFButton>}
                        </div>
                    </>}
            </div>
        </section>
    )

}

