import React, {useEffect, useState} from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { IconKebabMenu, BFButton, ButtonType } from '@integration-frontends/common/ui';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { dateFormatter } from '@integration-frontends/common/utils/date-formatter';

import {PageHeader} from '../common';

import {
  HeaderTitle,
 } from '@integration-frontends/workflow-manager/ui';


import {
  workflowEntitySelectors,
  selectSelectedClientId,
  workflowsPageEntered,
} from '@integration-frontends/workflow-manager/core/application';

import './workflows-page.scss';

export const WorkflowsPage = () => {
  const dispatch = useDispatch();

  const [createWorkflowOpen, setCreateWorkflowOpen] = useState(false);

  const selectedClient = useSelector(selectSelectedClientId);

  useEffect(() => {
    dispatch(workflowsPageEntered());
  }, [])
  const workflows = useSelector(workflowEntitySelectors.selectAll);

  return (
    <>
    <HeaderTitle/>
    <section className="workflows-page">
      <PageHeader onClick={() => dispatch(push('create-workflow/app-info'))} title="All workflows" actionTitle="Add workflow"/>
      <table id="workflows-page-table" className="workflows-table">
        <tbody>
        <tr className="workflows-table-header-row workflows-table-row">
          <th>Integration</th>
          <th>Workflow name</th>
          <th>Brandfolder</th>
          <th>Total assets affected</th>
          <th>Status</th>
          <th>Created</th>
          <th>Last Updated</th>
          <th>Updated by</th>
          <th></th>
        </tr>
        {(workflows || []).map((workflow) => (
          <WorkflowTile workflow={workflow} />
        ))}
        </tbody>
      </table>
    </section>
    </>
  )
}

function ActiveSignal({ active }){
  return(
    <div className={classNames('active-signal', { active, inactive: !active })}>
    <span className="signal-dot">&#x2022;</span> {(active ? 'active' : 'inactive')}
    </div>
  )
}
export const WorkflowTile = ({ workflow }) => {
  const dispatch = useDispatch();
  const dateFormat = 'MM/DD/YY';
  return (
    <tr className="workflows-table-row">
      <td>{workflow.integration_type}</td>
      <td>{workflow.workflow_name}</td>
      <td>{workflow.brandfolder}</td>
      <td>{workflow.total_assets_affected}</td>
      <td><ActiveSignal active={workflow.active}/></td>
      <td>{dateFormatter(workflow.created_at, dateFormat)}</td>
      <td>{dateFormatter(workflow.updated_at, dateFormat)}</td>
      <td>{workflow.last_updated_by}</td>
      <td><WorkflowMenuWorkflow workflowId={workflow?.id}/></td>
    </tr>
  )
}

export interface WorkflowMenuPopoutProps {
  workflowId: string;
}



function Panel({ workflowId }: WorkflowMenuPopoutProps) {
  const dispatch = useDispatch();


  return (
    <div className="menu-card-popover">
      <a
        className="font-medium menu-card-item"
        target="_blank"
        rel="noopener noreferrer"
        href="#"
      >
        EDIT
      </a>
      <a
        className="font-medium menu-card-item"
        target="_blank"
        rel="noopener noreferrer"
        href="#"
      >
        REMOVE
      </a>
    </div>
  );
}

export interface WorkflowMenuPopoutProps {
  workflowId: string;
}

export function WorkflowMenuWorkflow({ workflowId }: WorkflowMenuPopoutProps) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [-6, 4] } }],
    strategy: 'absolute',
  });

  return (
    <Popover data-testid="main-menu" data-cy="popover-menu" style={{ zIndex: 1000 }}>
      <Popover.Button
        as="button"
        ref={setReferenceElement}
        aria-label="open menu"
        className="icon-kebab-menu-container"
      >
        <span className={classNames('icon-kebab-menu-button', { active: !!popperElement })}>
          <IconKebabMenu />
        </span>
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Panel workflowId={workflowId} />
      </Popover.Panel>
    </Popover>
  );
}
