import { ILogger } from '@integration-frontends/core';
import { injectable } from 'inversify';
import 'isomorphic-fetch';
import jwtDecode from 'jwt-decode';
import {
  ApiFetchDataResponse,
  ApiListDataResponse,
  ApiSearchableThingsResponse,
  AssetDto,
  AssetOptions,
  AttachmentDto,
  AttachmentInputDto,
  AttachmentOptions,
  BrandfolderDto,
  BrandfolderOptions,
  CollectionDto,
  CollectionOptions,
  CreateClientBody,
  CreateHighspotCredentialBody,
  CustomFieldKeyDto,
  CustomFieldKeyResponseData,
  CustomFieldKeyResponseDatum,
  CustomFieldKeyValueDto,
  CustomFieldValueDto,
  CustomFieldValueOptions,
  CustomFieldValueResponseDto,
  GenericFileDto,
  LabelDto,
  Options,
  optionsToQueryString,
  OrganizationDto,
  OrganizationOptions,
  ResourceType,
  SearchFilterDto,
  SectionDto,
  SectionOptions,
  ShareManifestDto,
  TagDto,
  UserDto,
} from './model';
import * as _promiseRetry from 'promise-retry';
import { default as _rollupPromiseRetry } from 'promise-retry';
import { isEmpty } from 'ramda';

// using "* as" breaks the rollup build so we need to use this workaround
// more info: https://github.com/rollup/rollup/issues/1267
const promiseRetry = _rollupPromiseRetry || _promiseRetry;

export const TEMPORAL_API_TOKEN = 'TEMPORAL_API';
const RETRY_COUNT = 3;

const getRequestsInFlight = {};

export type GetOptions = Options & {
  batchRequests?: boolean;
};

@injectable()
export class TemporalApi {
  constructor(
    private baseUrl: string,
    private logger: ILogger,
    private refreshApiKey?: () => Promise<string>,
  ) {}

  async createHighspotWorkflow(apiKey: string, attributes: any) {
    return await this.post(apiKey, '/highspot/workflow', attributes);
  }

  async listClients(apiKey: string) {
    return await this.get(apiKey, '/v1/workflow-manager/clients');
  }

  async createClient(apiKey: string, attributes: CreateClientBody) {
    return await this.post(apiKey, '/v1/workflow-manager/clients', {data: { attributes }});
  }

  async listCredentials(apiKey: string, clientId: string) {
    return await this.get(apiKey, '/v1/admin/credentials', {}, {headers: {'Wfm-Client-Id': clientId }});
  }

  async createHighspotCredential(apiKey: string, attributes: CreateHighspotCredentialBody, clientId: string) {
    return await this.post(apiKey, '/v1/admin/credentials', {data: { attributes }}, {headers: {'Wfm-Client-Id': clientId }});
  }

  async listWorkflows(apiKey: string, clientId) {
    return await this.get(apiKey, `/v1/workflow-manager/clients/${clientId}/workflows`, {}, {headers: {'Wfm-Client-Id': clientId }});
  }

  async getHighspotFormInfo(apiKey: string, attributes) {
    return await this.get(apiKey, `/v1/workflow-manager/highspot/form-info`, {data: { attributes }});
  }

  async getBrandfolderFormInfo(apiKey: string, attributes) {
    return await this.get(apiKey, `/v1/workflow-manager/brandfolder/form-info`, {data: { attributes }});
  }

  private async get(
    apiKey: string,
    path: string,
    options: GetOptions = {},
    init: RequestInit = {},
  ) {
    const { batchRequests = true } = options;
    const callString = `${apiKey}${path}${optionsToQueryString(options)}`;

    function fetchData(this: TemporalApi) {
      return promiseRetry(async (retry, counter) => {
        function handleRetry() {
          if (counter <= RETRY_COUNT) {
            retry();
          } else {
            return null;
          }
        }

        try {
          const data = await this.fetchFromApi(apiKey, `${path}${optionsToQueryString(options)}`, {
            ...init,
            method: 'GET',
          });

          if (!data || isEmpty(data)) {
            return handleRetry();
          }

          return data;
        } catch (e) {
          this.logger.error(e);
          return handleRetry();
        }
      });
    }

    if (batchRequests) {
      if (!getRequestsInFlight[callString]) {
        const dataPromise = fetchData.bind(this)();
        getRequestsInFlight[callString] = dataPromise;
        dataPromise.then(() => (getRequestsInFlight[callString] = false));
      }
      return await getRequestsInFlight[callString];
    } else {
      return await fetchData.bind(this)();
    }
  }

  private async post(apiKey: string, path: string, body: any, init: RequestInit = {}) {
    const response = await this.fetchFromApi(apiKey, `${path}`, {
      ...init,
      method: 'POST',
      body: JSON.stringify(body),
    });
    handleApiErrors(response);
    return response;
  }

  private async fetchFromApi(apiKey: string, path: string, init: RequestInit = {}) {
    try {
      if (isExpired(apiKey)) {
        apiKey = await this.refreshApiKey();
      }

      const response = await fetch(`${this.baseUrl}${path}`, {
        ...init,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
          ...init.headers,
        },
      });
      if (isError401(response)) {
        apiKey = await this.refreshApiKey();
        return await this.fetchFromApi(apiKey, path, init);
      } else {
        return await response.json();
      }
    } catch (e) {
      this.logger.error(e);
      throw e;
    }
  }
}

function isError401(response): boolean {
  return response.status === 401;
}

interface decodedApiKeyProperties {
  exp?: number;
}

function getDecodedApiKey(key: string): decodedApiKeyProperties {
  return jwtDecode(key);
}

function isExpired(key: string): boolean {
  try {
    const { exp } = getDecodedApiKey(key);
    if (exp && exp - new Date().getTime() / 1000 < 60) {
      return true;
    }
    return false;
  } catch (e) {
    // if decoding fails then we're dealing with an API key, not an oauth token
    return false;
  }
}

function handleApiErrors(response) {
  handleErrors(response.errors);
}

function handleErrors(errors) {
  if (errorsExist(errors)) {
    throw errors;
  }
}

function errorsExist(errors) {
  try {
    return Object.keys(errors).length > 0;
  } catch (error) {
    return false;
  }
}
