import { AuthUiModule } from '@integration-frontends/common/auth/ui';
import { all, fork } from 'redux-saga/effects';
import { rootWorkflow } from './workflows';
import { appRootReducer } from '@integration-frontends/common/app';
import {
  enteredClientsPageEffects,
  entitiesStateReducer,
  createWorkflowReducer,
  createCredentialReducer,
  createClientEffects,
  createCredentialEffects,
  enteredWorkflowsPageEffects,
  workflowInfoFormEffects,
  enteredCredentialPageEffects,
 } from '@integration-frontends/workflow-manager/core/application';
import storage from 'redux-persist/lib/storage';
import {
  notificationEffects,
  notificationsRootReducer,
} from '@integration-frontends/common/notifications';
import { createAppStore } from '@integration-frontends/apps/common/store/create-app-store';
import { createBrowserHistory } from 'history';

// TODO add configs ~PP
const ENVIRONMENT = 'development';

const rootSaga = function* () {
  yield all([
    fork(rootWorkflow),
    fork(notificationEffects),
    fork(enteredClientsPageEffects),
    fork(createClientEffects),
    fork(createCredentialEffects),
    fork(enteredWorkflowsPageEffects),
    fork(workflowInfoFormEffects),
    fork(enteredCredentialPageEffects),
  ]);
};

export const { history, persistor, store } = createAppStore({
  environment: ENVIRONMENT,
  reducers: {
    ...notificationsRootReducer,
    ...appRootReducer,
    createWorkflowReducer,
    createCredentialReducer,
    ...AuthUiModule.getRootReducer(),
    entitiesStateReducer,
  },
  rootSaga,
  persistConfig: {
    enabled: true,
    storage,
  },
  history: createBrowserHistory(),
});
