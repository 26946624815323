import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';
import {
  AuthenticationServiceTokenStrategy,
  IdentityStoreLocalStorageStrategy,
} from '@integration-frontends/common/auth/core/infrastructure';
import { AuthUiModule } from '@integration-frontends/common/auth/ui';
import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
} from '@integration-frontends/common/brandfolder-api';
import {
  TEMPORAL_API_TOKEN,
  TemporalApi,
} from '@integration-frontends/common/temporal-api';
import {
  BFHistory,
  BFStore,
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
  HISTORY_TOKEN,
  ILogger,
  LOGGER_TOKEN,
  STORE_TOKEN,
} from '@integration-frontends/core';

import {
  IClientRepo,
  ICredentialRepo,
  IWorkflowRepo,
  WorkflowRepoMock,
  WORKFLOW_REPO_TOKEN,
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
  FormInfoRepoMock,
  CLIENT_REPO_TOKEN,
  CREDENTIAL_REPO_TOKEN,
  CredentialRepoMock,
} from '@integration-frontends/workflow-manager/core/model';

import {
  GET_API_KEY_TOKEN,
  ClientRepo,
  CredentialRepo,
  IGetAuthToken,
  WorkflowRepo,
  FormInfoRepo,
} from '@integration-frontends/workflow-manager/infrastructure/isomorphic';

import { IntegrationUiModule } from '@integration-frontends/integration/ui';
import { Container } from 'inversify';
import { createGetApiKeyCustomStrategy } from './app/get-api-key';
import { BF_BASE_URL, BF_STATIC_ASSETS_BASE_URL, BF_WEBSITE_BASE_URL, TEMPORAL_BASE_URL } from './config';
// import './i18n';
import { store, history } from './app/store';
import { BFStorage, STORAGE_TOKEN } from '@integration-frontends/core/storage';
import { safeLocalStorage } from '@apps-common/utils';
//
// // TODO: use BFModule. ~PP
//
// /**
//  * App
//  */
const appContainer = new Container();
appContainer.bind<ILogger>(LOGGER_TOKEN).toConstantValue(console);
appContainer.bind<IIdentityStore>(IDENTITY_STORE_TOKEN).to(IdentityStoreLocalStorageStrategy);
appContainer.bind<BFStorage>(STORAGE_TOKEN).toConstantValue(safeLocalStorage);
DI_CONTAINER.merge(appContainer);
// /**
//  * Store
//  */
const storeContainer = new Container();
storeContainer.bind<BFStore>(STORE_TOKEN).toConstantValue(store);
storeContainer.bind<BFHistory>(HISTORY_TOKEN).toConstantValue(history);
DI_CONTAINER.merge(storeContainer);
// /**
//  * Auth
//  */
const authContainer = new Container();
authContainer
  .bind<IAuthenticateService>(AUTHENTICATE_SERVICE_TOKEN)
  .to(AuthenticationServiceTokenStrategy);
//
AuthUiModule.initialize(authContainer);
//
// /**
//  * Integration
//  */
const integrationContainer = new Container();
//
integrationContainer
  .bind<BrandfolderApi>(BRANDFOLDER_API_TOKEN)
  .toConstantValue(new BrandfolderApi(BF_BASE_URL, DI_CONTAINER.get(LOGGER_TOKEN)));

integrationContainer
  .bind<TemporalApi>(TEMPORAL_API_TOKEN)
  .toConstantValue(new TemporalApi(TEMPORAL_BASE_URL, DI_CONTAINER.get(LOGGER_TOKEN)));
//
integrationContainer
  .bind<IGetAuthToken>(GET_API_KEY_TOKEN)
  .toConstantValue(createGetApiKeyCustomStrategy(store));

integrationContainer.bind<IClientRepo>(CLIENT_REPO_TOKEN).to(ClientRepo);

// integrationContainer.bind<ICredentialRepo>(CREDENTIAL_REPO_TOKEN).to(CredentialRepo);
integrationContainer.bind<ICredentialRepo>(CREDENTIAL_REPO_TOKEN).to(CredentialRepoMock);

integrationContainer.bind<IWorkflowRepo>(WORKFLOW_REPO_TOKEN).to(WorkflowRepo);
// integrationContainer.bind<IWorkflowRepo>(WORKFLOW_REPO_TOKEN).to(WorkflowRepoMock);

// integrationContainer.bind<IFormInfoRepo>(FORM_INFO_REPO_TOKEN).to(FormInfoRepo);
integrationContainer.bind<IFormInfoRepo>(FORM_INFO_REPO_TOKEN).to(FormInfoRepoMock);

export const componentResolver = new ComponentResolver({});

integrationContainer
  .bind<ComponentResolver>(COMPONENT_RESOLVER_TOKEN)
  .toConstantValue(componentResolver);

DI_CONTAINER.merge(integrationContainer);
