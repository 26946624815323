import { all, call, put, takeEvery, select } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  credentialEntityActions,
  selectSelectedClientId,
  setActiveSection,
  selectHighspotSecretKey,
  selectHighspotApiKey,
  selectSelectedIntegrationType,
} from '@integration-frontends/workflow-manager/core/application';
import {
  CREDENTIAL_REPO_TOKEN,
  ICredentialRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import { createCredential, credentialLoading, credentialSuccess, credentialFailure } from '../../../create-credential';
import {setSelectedCredentialId} from '../../../create-workflow';

function* handler(action: ReturnType<typeof createCredential>) {
  yield put(credentialLoading())
  const clientKey = yield select(selectHighspotApiKey);
  const secretKey = yield select(selectHighspotSecretKey);
  const integrationType = yield select(selectSelectedIntegrationType);
  const credentialRepo: ICredentialRepo = DI_CONTAINER.get(CREDENTIAL_REPO_TOKEN);
  const selectedClientId = yield select(selectSelectedClientId);

  const credentialInfo = {client_key: clientKey, client_secret: secretKey, credential_type: integrationType};
  const credential = yield call(credentialRepo.createCredential, selectedClientId, credentialInfo);

  if (credential) {
    yield put(credentialEntityActions.credentialReceived(credential));
    yield put(credentialSuccess());
    yield put(setSelectedCredentialId({credentialId: credential.id}));
    yield put(setActiveSection({activeSection: 'workflow-info'}));
  } else {
    yield put(credentialFailure())
    yield put(createNotification({message: "Error creating credential", location: 'topLevel', type: NotificationType.Error}));
  }
}

export function* createCredentialEffects() {
  yield takeEvery(createCredential, handler);
}
