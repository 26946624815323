import { DI_CONTAINER, ILogger, LOGGER_TOKEN } from '@integration-frontends/core';
import {
  assetEntityActions,
  attachmentEntityActions,
  IntegrationEntitiesSelectors,
} from '@integration-frontends/integration/core/application/common';
import {
  SearchAssetsGroupBy,
  searchError,
} from '@integration-frontends/integration/core/application';
import {
  Asset,
  ASSET_REPO_TOKEN,
  AssetsListResultSet,
  Attachment,
  Container,
  filterSectionsByContainer,
  IAssetRepo,
  ListOptions,
  PagedResults,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';
import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { search, searchAssetsActions, searchRefresh, searchSuccess } from '../actions';
import { SearchAssetsSelectors } from '../selectors';
import { sendSegmentAction } from '@integration-frontends/common/analytics';

export const SEARCH_DEBOUNCE = 500;

const handler = function* (
  action: ReturnType<typeof search>,
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  const { searchParams, sortOptions } = action.payload;
  const pageSize = yield select(searchAssetsSelectors.pageSize);
  const container = yield select(searchAssetsSelectors.container);
  const groupBy = yield select(searchAssetsSelectors.groupBy);
  if (groupBy === SearchAssetsGroupBy.Section) {
    yield handleBySection(
      action,
      searchAssetsSelectors,
      entitiesSelectors,
      container,
      searchParams,
      pageSize,
      sortOptions,
    );
  } else {
    yield handleByContainer(
      action,
      searchAssetsSelectors,
      entitiesSelectors,
      container,
      searchParams,
      pageSize,
      sortOptions,
    );
  }
};

const handleByContainer = function* (
  action: ReturnType<typeof search>,
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
  container,
  searchParams,
  pageSize,
  sortOptions,
) {
  const results: PagedResults<AssetsListResultSet> = yield call(
    fetchContainerAssets,
    container,
    searchParams,
    pageSize,
    sortOptions,
  );

  const { assets, attachments } = results.data;

  yield put(searchAssetsActions.pageLoaded({ results }));

  yield put(assetEntityActions.assetsReceived(assets));
  yield put(attachmentEntityActions.attachmentsReceived(attachments));
  yield put(sendSegmentAction({ event: 'searchAssetsSuccess' }));
  yield put(searchSuccess({ resultIds: assets.map(prop('id')) }));
};

const handleBySection = function* (
  action: ReturnType<typeof search>,
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
  container,
  searchParams,
  pageSize,
  sortOptions,
) {
  const sections = yield select(
    createSelector(entitiesSelectors.section.selectAll, (sections) =>
      sections.filter(filterSectionsByContainer(container)),
    ),
  );

  const sectionsPagedResults: {
    sectionId: string;
    results: PagedResults<{ assets: Asset[]; attachments: Attachment[] }>;
  }[] = yield all(
    sections.map(function* (section) {
      const results: PagedResults<AssetsListResultSet> = yield call(
        fetchSectionAssets,
        container,
        section.id,
        searchParams,
        pageSize,
        sortOptions,
      );

      return {
        sectionId: section.id,
        results,
      };
    }),
  );

  const assets = sectionsPagedResults.flatMap(({ results }) => results.data.assets);
  const attachments = sectionsPagedResults.flatMap(({ results }) => results.data.attachments);

  yield all(
    sectionsPagedResults.map((result) => put(searchAssetsActions.sectionPageLoaded(result))),
  );

  yield put(assetEntityActions.assetsReceived(assets));
  yield put(attachmentEntityActions.attachmentsReceived(attachments));
  yield put(sendSegmentAction({ event: 'searchAssetsSuccess' }));
  yield put(searchSuccess({ resultIds: assets.map(prop('id')) }));
};

function fetchSectionAssets(
  container: Container,
  sectionId: string,
  searchParams: SearchParameters,
  pageSize: number,
  sortOptions: SortOptions,
) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const options: ListOptions = {
    searchParams,
    pagination: { perPage: pageSize },
    sort: sortOptions,
  };
  return assetRepo.listContainerSectionAssets(container, sectionId, options);
}

function fetchContainerAssets(
  container: Container,
  searchParams: SearchParameters,
  pageSize: number,
  sortOptions: SortOptions,
) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const options: ListOptions = {
    searchParams,
    pagination: { perPage: pageSize },
    sort: sortOptions,
  };
  return assetRepo.listContainerAssets(container, options);
}

export function* searchEffects(
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  const logger: ILogger = DI_CONTAINER.get(LOGGER_TOKEN);

  yield takeLatest(search, function* (action) {
    yield delay(SEARCH_DEBOUNCE);

    while (true) {
      try {
        yield handler(action, searchAssetsSelectors, entitiesSelectors);
      } catch (e) {
        yield put(searchError());
        logger.error(e);
      }

      yield take(searchRefresh);
    }
  });
}
