import { all, call, put, takeEvery } from 'typed-redux-saga';
import {
  clientEntityActions,
} from '@integration-frontends/workflow-manager/core/application';
import {
  CLIENT_REPO_TOKEN,
  IClientRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import { clientsPageEntered } from '../../clients-page';

function* handler() {
  try {
    const clientRepo: IClientRepo = DI_CONTAINER.get(CLIENT_REPO_TOKEN);
    const clients = yield call(clientRepo.listClients);

    yield put(clientEntityActions.clientsReceived(clients));
    yield put(pageLoaded());
  } catch (e) {
    yield put(pageLoadError());
  }
}

export function* enteredClientsPageEffects() {
  yield takeEvery(clientsPageEntered, handler);
}
