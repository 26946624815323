import { createReducer } from '@reduxjs/toolkit';
import {
  selectClient,
  loading,
  success,
  failure,
  setActiveSection,
  highspotSelectWorkflowName,
  highspotSelectBrandfolder,
  highspotSelectSection,
  highspotSelectSpot,
  highspotSelectIntegrationID,
  highspotSelectAPIHost,
  setSelectedCredentialId,
  setBrandfolderFormInfo,
} from './actions';

export interface CreateWorkflowState {
  loading: boolean;
  selectedClientId: string;
  selectedCredentialId: string;
  selectedIntegrationType: string;
  uploading: boolean;
  highspot: HighspotWorkflow;
  activeSection: string;
  credential: Credential | null;
  brandfolderFormInfo: any;
}

export interface HighspotWorkflow {
  active: boolean;
  api_host: string;
  bf_source_key: string;
  client_id: string;
  workflow_name: string;
  collection_to_folder_map: {
    [key: string]: string;
  };
  credential_id: string;
  integration_id: string;
  secret_id: string;
  section_to_folder_map: {
    [key: string]: string;
  };
  spot_id: string;
  user_email: string;
  selected_section_id: string;
}

export interface Credential {
  access_token: string,
  api_key: string,
  brandfolder_account_id: number,
  client_key: string,
  client_secret: string,
  created_at: string,
  credential_type: string,
  email: string,
  expires_at: string,
  external_user_id: string,
  id: string,
  refresh_token: string,
  scopes: string,
  updated_at: string
}


export const highspotWorkflowInitialState: HighspotWorkflow = {
  active: true,
  api_host: '',
  bf_source_key: null,
  selected_section_id: null,
  client_id: null,
  credential_id: null,
  workflow_name: '',
  collection_to_folder_map: {},
  integration_id: '',
  secret_id: null,
  section_to_folder_map: {},
  spot_id: null,
  user_email: null
}

export const createWorkflowInitialState: CreateWorkflowState = {
  loading: false,
  selectedClientId: null,
  selectedCredentialId: null,
  selectedIntegrationType: null,
  uploading: false,
  highspot: highspotWorkflowInitialState,
  credential: null,
  activeSection: 'app-info',
  brandfolderFormInfo: null,
};

export const createWorkflowReducer = createReducer(createWorkflowInitialState, (builder) =>
  builder
  .addCase(selectClient, (state, action) => {
    state.selectedClientId = action.payload.clientId;
  })
  .addCase(loading, (state) => {
    state.loading = true;
  })
  .addCase(success, state => {
    state.loading = false;
  })
  .addCase(failure, (state) => {
    state.loading = false;
  })
  .addCase(setActiveSection, (state, action) => {
    state.activeSection = action.payload.activeSection;
  })
  .addCase(setSelectedCredentialId, (state, action) => {
    state.selectedCredentialId = action.payload.credentialId;
  })
  .addCase(setBrandfolderFormInfo, (state, action) => {
    state.brandfolderFormInfo = action.payload;
  })

  //Highspot

  .addCase(highspotSelectWorkflowName, (state, action) => {
    state.highspot.workflow_name = action.payload.workflowName;
  })
  .addCase(highspotSelectBrandfolder, (state, action) => {
    state.highspot.bf_source_key = action.payload.brandfolder;
  })
  .addCase(highspotSelectSection, (state, action) => {
    state.highspot.selected_section_id = action.payload.section;
  })
  .addCase(highspotSelectSpot, (state, action) => {
    state.highspot.spot_id = action.payload.spot;
  })
  .addCase(highspotSelectIntegrationID, (state, action) => {
    state.highspot.integration_id = action.payload.integrationID;
  })
  .addCase(highspotSelectAPIHost, (state, action) => {
    state.highspot.api_host = action.payload.apiHost;
  })
);

export interface CreateWorkflowRootState {
  createWorkflowReducer: CreateWorkflowState;
}
