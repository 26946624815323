import { combineReducers } from '@reduxjs/toolkit';
import {
  brandfolderSlice,
  collectionSlice,
  organizationSlice,
  sectionSlice,
} from '@integration-frontends/integration/core/application/common/entities-state/entities';
import { clientSlice } from './entities/client';
import { credentialSlice } from './entities/credential';
import { workflowSlice } from './entities/workflow';
import { formInfoSlice } from './entities/form-info';

export const entitiesStateReducer = combineReducers({
  brandfolders: brandfolderSlice.reducer,
  collections: collectionSlice.reducer,
  organizations: organizationSlice.reducer,
  sections: sectionSlice.reducer,
  clients: clientSlice.reducer,
  formInfo: formInfoSlice.reducer,
  workflows: workflowSlice.reducer,
  credentials: credentialSlice.reducer,
});
