import React, {useEffect, useState} from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { IconKebabMenu, BFButton, ButtonType } from '@integration-frontends/common/ui';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

import {CreateClientForm} from './create-client'
import {PageHeader} from '../common';

import {
  HeaderTitle,
 } from '@integration-frontends/workflow-manager/ui';

import {
  clientEntitySelectors,
  clientsPageEntered,
  selectClient,
} from '@integration-frontends/workflow-manager/core/application';

import './clients-page.scss';

export const ClientsPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clientsPageEntered());
  }, [])

  const [createClientOpen, setCreateClientOpen] = useState(false);

  const clients = useSelector(clientEntitySelectors.selectAll);

  return (
    <>
    <section className="clients-page">
      <PageHeader onClick={() => setCreateClientOpen(!createClientOpen)} title="All clients" actionTitle="Add client"/>
      {(createClientOpen &&
        <CreateClientForm
        modal={true}
        onClose={() => setCreateClientOpen(false)}
        />
      )}
      <table id="clients-page-table" className="clients-table">
        <tbody>
        <tr className="clients-table-header-row clients-table-row">
          <th>Client Name</th>
          <th>Integrations</th>
          <th>Last Updated</th>
          <th></th>
        </tr>
        {(clients || []).map((client) => (
          <ClientTile client={client} />
        ))}
        </tbody>
      </table>
    </section>
    </>
  )
}

export const ClientTile = ({ client }) => {
  const dispatch = useDispatch();

  const onSelectClient = () => {
    dispatch(selectClient({ clientId: client.id }));
    dispatch(push('/workflows'));
  }

  return (
    <tr className="clients-table-row" onClick={() => onSelectClient()}>
      <td>{client?.client_name}</td>
      <td>{client?.workflows?.length}</td>
      <td>{(new Date()).toDateString()}</td>
      <td><MenuClient clientId={client?.id}/></td>
    </tr>
  )
}

export interface MenuPopoutProps {
  clientId: string;
}

function Panel({ clientId }: MenuPopoutProps) {
  const dispatch = useDispatch();


  return (
    <div className="menu-card-popover">
      <a
        className="font-medium menu-card-item"
        target="_blank"
        rel="noopener noreferrer"
        href="#"
      >
        EDIT
      </a>
      <a
        className="font-medium menu-card-item"
        target="_blank"
        rel="noopener noreferrer"
        href="#"
      >
        REMOVE
      </a>
    </div>
  );
}

export interface MenuPopoutProps {
  clientId: string;
}

export function MenuClient({ clientId }: MenuPopoutProps) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [-6, 4] } }],
    strategy: 'absolute',
  });

  return (
    <Popover data-testid="main-menu" data-cy="popover-menu" style={{ zIndex: 1000 }}>
      <Popover.Button
        as="button"
        ref={setReferenceElement}
        aria-label="open menu"
        className="icon-kebab-menu-container"
      >
        <span className={classNames('icon-kebab-menu-button', { active: !!popperElement })}>
          <IconKebabMenu />
        </span>
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Panel clientId={clientId} />
      </Popover.Panel>
    </Popover>
  );
}
