import { withFormControlStyles } from '@integration-frontends/common/ui/forms/form-controls/common';
import React, { HTMLAttributes, SelectHTMLAttributes } from 'react';
import { COMPONENT_RESOLVER_TOKEN, ComponentResolver } from '@integration-frontends/core';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';

export const MULTI_SELECT_COMPONENT_KEY = 'MULTI_SELECT';

export enum BFMultiSelectSize {
  Small = 's',
  Medium = 'm',
}

export type BFMultiSelectOption = {
  value: string | number;
};

export type BFMultiSelectProps = {
  options: BFMultiSelectOption[];
  selected: BFMultiSelectOption[];
  onChange: any;
  isClearable?: boolean;
  size?: BFMultiSelectSize;
  selectedOptions?: BFMultiSelectOption[];
  getOptionKey: (opton: BFMultiSelectOption) => string | number;
} & Omit<HTMLAttributes<HTMLElement>, 'onChange'> &
  Omit<SelectHTMLAttributes<HTMLElement>, 'onChange' | 'size'>;

function DefaultComponent(props: BFMultiSelectProps) {
  return null;
}

export const BFMultiSelect = withFormControlStyles<BFMultiSelectProps>((props) => {
  const componentResolver = useDependencyInjector<ComponentResolver>(COMPONENT_RESOLVER_TOKEN);
  const Component = componentResolver.getComponent(MULTI_SELECT_COMPONENT_KEY) || DefaultComponent;
  return <Component {...props} />;
});
