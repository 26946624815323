import {
  GetBrandfolderFormInfoBody,
} from '@integration-frontends/common/temporal-api';
import {
  Brandfolder,
  IFormInfoRepo,
  brandolderFormInfoMockData,
} from '@integration-frontends/workflow-manager/core/model';
import { injectable } from 'inversify';

@injectable()
export class FormInfoRepoMock implements IFormInfoRepo {
  getBrandfolderFormInfo(attributes: GetBrandfolderFormInfoBody): Promise<Brandfolder[]> {
    return Promise.resolve(brandolderFormInfoMockData);
  };
}
