import React from 'react';
import { BFButton } from '@integration-frontends/common/ui';
import './page-header.scss';

export interface PageHeaderProps {
  title: string;
  action: () => void;
  actionTitle: string;
}

export const PageHeader = ({title, onClick, actionTitle}) => (
  <header className="flex flex-row page-header">
    <h1 className="page-header-title">{title}</h1>
    <BFButton className="page-header-action flex flex-row" onClick={onClick}>
      <div className="flex flex-row">
        <div className="add-icon"></div>
        <div>{actionTitle}</div>
      </div>
    </BFButton>
  </header>
)
