import {
  WorkflowListItem,
  workflowsMockData,
  IWorkflowRepo,
} from '@integration-frontends/workflow-manager/core/model';

import { injectable } from 'inversify';

@injectable()
export class WorkflowRepoMock implements IWorkflowRepo {
  listWorkflows(): Promise<WorkflowListItem[]> {
    return Promise.resolve(workflowsMockData);
  }
}
