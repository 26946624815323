import { all, call, put, takeEvery, select } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  credentialEntityActions,
  selectSelectedClientId,
  setActiveSection,
  selectHighspotSecretKey,
  selectHighspotApiKey,
  selectSelectedIntegrationType,
} from '@integration-frontends/workflow-manager/core/application';
import {
  CREDENTIAL_REPO_TOKEN,
  ICredentialRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import { enteredCredentialPage, credentialLoading, credentialSuccess, credentialFailure } from '../../../create-credential';

function* handler(action: ReturnType<typeof enteredCredentialPage>) {
  yield put(credentialLoading())
  const credentialRepo: ICredentialRepo = DI_CONTAINER.get(CREDENTIAL_REPO_TOKEN);
  const selectedClientId = yield select(selectSelectedClientId);
  const credentials = yield call(credentialRepo.listCredentials, selectedClientId);
  if (credentials) {
    yield put(credentialEntityActions.credentialsReceived(credentials));
    yield put(credentialSuccess());
  } else {
    yield put(credentialFailure())
    yield put(createNotification({message: "Error fetching credentials", location: 'topLevel', type: NotificationType.Error}));
  }
}

export function* enteredCredentialPageEffects() {
  yield takeEvery(enteredCredentialPage, handler);
}
