import { createSelector } from '@reduxjs/toolkit';
import { CreateCredentialState, CreateCredentialRootState, HighspotCredential } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';


const selectCreateCredentialState: StateSelector<CreateCredentialState> = (state: CreateCredentialRootState) => state.createCredentialReducer;
export const selectCreateCredentialLoading = createSelector(selectCreateCredentialState, (state) => state.credentialLoading);

export const selectSelectedIntegrationType = createSelector(selectCreateCredentialState, (state) => state.integrationType);

// High Spot
const selectHighspotState: StateSelector<HighspotCredential> = (state: CreateCredentialRootState) => state.createCredentialReducer.highspot;

export const selectHighspotApiKey = createSelector(selectHighspotState, (state) => state.client_key);
export const selectHighspotSecretKey = createSelector(selectHighspotState, (state) => state.secret_key);
