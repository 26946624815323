import React from 'react';
import {useSelector} from 'react-redux';

export const HighspotReview = () => {
  return (
    <>
      <section className="review-section">
        <h2>Organization info</h2>
        <ul>
          <li>
            <div className="review-attribute-title">Client</div>
            <div className="review-attribute-value"></div>
          </li>
          <li>
            <div className="review-attribute-title">Brandfolder API key</div>
            <div className="review-attribute-value"></div>
          </li>
        </ul>
      </section>

      <section className="review-section">
        <h2>Integration info</h2>
        <ul>
          <li>
            <div className="review-attribute-title">Integration</div>
            <div className="review-attribute-value"></div>
          </li>
          <li>
            <div className="review-attribute-title">Highspot API key</div>
            <div className="review-attribute-value"></div>
          </li>
          <li>
            <div className="review-attribute-title">Highspot secret API key</div>
            <div className="review-attribute-value"></div>
          </li>
        </ul>
      </section>
      <section className="review-section">
        <h2>Workflow info</h2>
        <ul>
          <li>
            <div className="review-attribute-title">Source (Brandfolder)</div>
            <div className="review-attribute-value"></div>
          </li>
          <li>
            <div className="review-attribute-title">Destination (Highspot)</div>
            <div className="review-attribute-value"></div>
          </li>
          <li>
            <div className="review-attribute-title">API host</div>
            <div className="review-attribute-value"></div>
          </li>
        </ul>
      </section>
    </>
  )
}
