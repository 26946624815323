import { createSelector } from '@reduxjs/toolkit';
import { CreateWorkflowState, CreateWorkflowRootState, HighspotWorkflow } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';


const selectCreateWorkflowState: StateSelector<CreateWorkflowState> = (state: CreateWorkflowRootState) => state.createWorkflowReducer;
export const selectCreateWorkflowLoading = createSelector(selectCreateWorkflowState, (state) => state.loading);

export const selectSelectedClientId = createSelector(selectCreateWorkflowState, (state) => state.selectedClientId);

export const selectSelectedCredentialId = createSelector(selectCreateWorkflowState, (state) => state.selectedCredentialId);

export const selectActiveSection = createSelector(selectCreateWorkflowState, (state) => state.activeSection);

export const selectBrandfolderFormInfo = createSelector(selectCreateWorkflowState, (state) => state.brandfolderFormInfo);

// High Spot
const selectHighspotState: StateSelector<HighspotWorkflow> = (state: CreateWorkflowRootState) => state.createWorkflowReducer.highspot;

export const selectHighspotWorkflowName = createSelector(selectHighspotState, (state) => state.workflow_name);
export const selectHighspotBrandfolder = createSelector(selectHighspotState, (state) => state.bf_source_key);
export const selectHighspotSection = createSelector(selectHighspotState, (state) => state.selected_section_id);

export const selectHighspotCredentialID = createSelector(selectHighspotState, (state) => state.credential_id);

export const selectHighspotIntegrationID = createSelector(selectHighspotState, (state) => state.integration_id);

export const selectHighspotAPIHost = createSelector(selectHighspotState, (state) => state.api_host);
