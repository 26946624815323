import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { BFSelect } from '@integration-frontends/common/ui/forms/form-controls/select';
import { BFLabel, BFInput } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import { BFCheckbox, BFMultiSelect, BFButton, Loader } from '@integration-frontends/common/ui';
import { CreateWorkflowNav } from '../create-workflow-nav';
import { SectionTracker } from '../section-tracker';

import {
  setIntegrationType,
  highspotSetClientKey,
  highspotSetSecretKey,
  selectHighspotApiKey,
  selectSelectedIntegrationType,
  selectHighspotSecretKey,
  selectActiveSection,
  selectCreateCredentialLoading,
  enteredCredentialPage,
} from '@integration-frontends/workflow-manager/core/application';


interface HighspotCredentialFormProps {
  onBack: () => void;
  onNext: () => void;
}


export const HighspotCredentialForm = ({onBack, onNext}: HighspotCredentialFormProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(enteredCredentialPage());
  }, []);
  const integrationType = useSelector(selectSelectedIntegrationType);
  const activeSection = useSelector(selectActiveSection);
  const loading = useSelector(selectCreateCredentialLoading);

  // highspot
  const clientKey = useSelector(selectHighspotApiKey);
  const secretKey = useSelector(selectHighspotSecretKey);

  const integrationTypes = [{label: 'Seismic', value: 'seismic'}, {label: 'Salsify', value: 'salsify'}, {label: 'Highspot', value: 'highspot'}]

  return (
    <>
      <CreateWorkflowNav
      onBack={onBack}
      onNext={onNext}
      title="Integration Info"
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection}/>
        <section className="forms-container">
        {(loading ? (
          <Loader />
        ) : (
          <>
            <h1>Integration Info</h1>
            <BFLabel htmlFor="select-integration-type">Integration Type</BFLabel>
            <BFSelect id="select-integration-type" options={integrationTypes}
            value={integrationTypes.filter((type) => integrationType === type.value)[0]?.value || null}
            onOptionChange={(e) => {
              dispatch(setIntegrationType({integrationType: `${e.value}`}));
            }}
            />

            <BFLabel htmlFor="select-api-key">Highspot API key</BFLabel>
            <BFInput
            id="select-api-key"
            value={clientKey}
            onChange={(e) => {
              dispatch(highspotSetClientKey({clientKey: e.target.value}));
            }}/>

            <BFLabel htmlFor="select-secret-key">Highspot secret key</BFLabel>
            <BFInput
            id="select-secret-key"
            value={secretKey}
            onChange={(e) => {
              dispatch(highspotSetSecretKey({secretKey: e.target.value}));
            }}/>
          </>
        ))}
        </section>
      </section>
    </>
  )
}
